import styled from 'styled-components/macro';
import {
  DialogContent,
  Button as MaterialButton
} from '@material-ui/core';

export const ClientChatContainer = styled.div`
  flex: 2.5;
`;

export const ClientChatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 53px);
`;

export const ModalContentContainer = styled(DialogContent)`
  display: flex;
  gap: 15px;
  padding: 10px 10px 26px;
  width: 1200px;
  max-width: calc(100%);
  flex-direction: column;
  min-height: 674px;
  background: #f0f0f0;
  margin-top: 42px;
  @media (min-width: 1100px) {
    flex-direction: row;
  }

  .UserInfo {
    flex: 1;
    padding: 0px;
    padding-bottom: 20px;
  }

  .UserComputerData {
    flex: 1;
  }

  .ColumnContainer {
    flex: 1;
  }

  .ChatDataListStyle {
    flex: 2.5;
  }

  .UserInfo {
    flex: 1;
  }
`;

export const TabsContainer = styled.div`
  display: flex;
  gap: 1px;
  margin-left: 20px;

  button {
    min-width: unset !important;
    padding: var(--padding-primary) !important;
    background: #fff;
    border: 1px solid;
    border-radius: 0px !important;
    color: var(--primary-dark-blue);
    margin: 0px;
    padding: 4px 8px !important;
    min-width: unset !important;  

    &.disabled {
      background: var(--primary-dark-blue); !important;
      color: #fff !important;
      border-color: var(--primary-dark-blue);
    }

    &:hover {
      background: transparent;
      color: var(--primary-dark-blue);
    }

    &:focus {
      background: transparent;
    }

    &:first-child {
      border-bottom-left-radius: 5px !important;
      border-top-left-radius: 5px !important;
    }

    &:last-child {
      border-bottom-right-radius: 5px !important;
      border-top-right-radius: 5px !important;
    }
  }
`;

export const TabButton = styled(MaterialButton)`
  border: solid 1px #b3b3b3;
  box-shadow: none;
  font-size: var(--second-font-size);
  padding: 4px 8px;
  font-weight: var(--wider-font-weight);
  color: #555;
`;