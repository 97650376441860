import moment from "moment";

export const formatTableData = (pastChats) => {
  const formattedList = [];
  for(let i = pastChats.length-1; i >= 0; i--){
    const originalTime = moment(pastChats[i].createdAt);
    formattedList.push([
      pastChats[i].agent,
      originalTime.add('hours').format('DD:MM:YYYY HH:mm'),
      pastChats[i]
    ])
  }

  return formattedList
}

export const tableHead = [
  'Agent',
  'Time',
]