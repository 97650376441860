import * as S from "./HistoryList.style";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, HistoryDetails } from "components";
import { tableHead, formatTableData } from "pages/HistoryPage/HistoryPage.config";
import { getChatMessages } from "redux_toolkit/reducers/history/historySlice";


export const HistoryList = memo(({
  setSelectedRowIndex,
  setHistoryDetailsItem,
  partnerId,
  selectedRowIndex,
  historyDetailsItem,
  historyList,
  notFound
})=> {
  const styles = S.useStyles();
  const dispatch = useDispatch();

  const { messagesList } = useSelector(({history})=> history);

  return(
    <S.TableContainer>
      <Table
        tableHead={tableHead}
        selectedRowIndex={selectedRowIndex}
        onRowClick={(item, index) => {
          setSelectedRowIndex(index);
          const messages = messagesList.get(item._id);
          if(!messages) {
            dispatch(getChatMessages({ partnerId: item.partnerId, chatId: item._id })).then((res)=> {
              setHistoryDetailsItem({...item, messages: res.payload.data});
            })
          };
          setHistoryDetailsItem({...item, messages});
        }}
        tbodyClassName={styles.tbody}
        theadClassName={styles.thead}
        tableData={formatTableData(historyList)}
        notFound={notFound}
        allScreen={true}
      />
      {!!historyDetailsItem && historyDetailsItem?.messages && (
        <HistoryDetails
          partnerId={partnerId}
          item={historyDetailsItem}
          visitorPartner={historyDetailsItem.visitorPartner[0].user}
          onClose={() => {
            setHistoryDetailsItem(null);
            setSelectedRowIndex(-1);
          }}
        />
      )}
    </S.TableContainer>
  )
}, (prevProps, nextProps)=> {
  return(
    prevProps.setHistoryDetailsItem === nextProps.setHistoryDetailsItem &&
    prevProps.setSelectedRowIndex === nextProps.setSelectedRowIndex &&
    prevProps.partnerId === nextProps.partnerId &&
    prevProps.selectedRowIndex === nextProps.selectedRowIndex &&
    prevProps.historyDetailsItem === nextProps.historyDetailsItem &&
    prevProps.historyList === nextProps.historyList &&
    prevProps.notFound === nextProps.notFound
  );
})