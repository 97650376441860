import { createSlice } from '@reduxjs/toolkit';
import { MESSAGE_STATUS, MESSAGES_ITEM_COUNT } from 'configs/constants';
import { enableMapSet } from 'immer';

enableMapSet();

const initialState = {
  chatRequests: new Set(),
  chats: new Map(),
  watchingChats: new Map(),
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setNewChat: (state, action)=> {
      const chat = action.payload.chat;
      let oldMessages = [];
      let listUnreadMsg = {};

      const data = [...state.chats.values()].find(item => item.companion._id === chat.companion._id);
      if(data) {
        state.chats.delete(data._id);
        oldMessages = data.messages;
      }

      for (let item of action.payload.visitorUnread) {
        listUnreadMsg[item] = true;
      }

      state.chats = state.chats.set(
        chat._id,
        {...chat, messages: [...oldMessages, ...action.payload.messages[0]], unreadCount: action.payload.unreadCount, visitorUnread: listUnreadMsg, chatOpen: data?.chatOpen, undeliveredMessages: {}}
      );
    },
    setUndeliveredMessage: (state, action)=> {
      const {chatId, message, file} = action.payload;
      const chatData = state.chats.get(chatId);
      const localUserId = chatData.user._id;

      // get next message index
      let countPlus = 1;
      countPlus += Object.keys(chatData.undeliveredMessages).length;
      const nextIndex = +(chatData.messages[chatData.messages.length-MESSAGES_ITEM_COUNT] || 0) + countPlus;

      const messageData = [nextIndex, localUserId, message, file || "", `${new Date()}`, MESSAGE_STATUS.UNDELIVERED];

      state.chats = state.chats.set(chatId, {
        ...chatData,
        undeliveredMessages: {...chatData.undeliveredMessages, [nextIndex]: messageData}
      })
    },
    setNewMessage: (state, action)=> {
      const chatId = action.payload.chatId;
      const messageId = action.payload.message[0];
      const chatHistory = state.chats.get(chatId);
      const watchingChatHistory = state.watchingChats.get(chatId);

      if(chatHistory){
        const newUndeliveredMessages = Object.fromEntries(
          Object.entries(chatHistory.undeliveredMessages).filter(([key]) => +key !== messageId)
        );

        state.chats = state.chats.set(chatId, {
          ...chatHistory,
          unreadCount: chatHistory.chatOpen? 0 : ++chatHistory.unreadCount,
          messages: [...(chatHistory.messages || []), ...action.payload.message],
          undeliveredMessages: newUndeliveredMessages,
          visitorUnread: {...chatHistory.visitorUnread, [messageId]: true}
        })
      }

      if(watchingChatHistory) {
        state.watchingChats = state.watchingChats.set(chatId, {
          ...watchingChatHistory,
          messages: [...(watchingChatHistory.messages || []), ...action.payload.message]
        })
      }
    },
    addHistory: (state, action)=> {
      const chatId = action.payload.chatId;
      const myPrevChatHistory = state.chats.get(chatId);
      if(myPrevChatHistory) {
        state.chats = state.chats.set(
          chatId,
          { ...(myPrevChatHistory || []), messages: [...action.payload.messages, ...(myPrevChatHistory?.messages || [])] }
        );
        return;
      }

      const prevChatHistory = state.watchingChats.get(chatId);
      if(prevChatHistory) {
        state.watchingChats = state.watchingChats.set(
          chatId,
          { ...(prevChatHistory || []), messages: [...action.payload.messages, ...(prevChatHistory?.messages || [])] }
        );
      }
    },
    endChatState: (state, action)=> {
      const chatId = action.payload.chatId;
      const myPrevChatHistory = state.chats.get(chatId);
      if(myPrevChatHistory) {
        state.chats = state.chats.set(chatId, { ...(myPrevChatHistory), chatEnd: true});
      }
    },
    deleteChat: (state, action)=> {
      state.chats.delete(action.payload.chatId);
    },
    incomingChats: (state, action) => {
      for(let item of action.payload){
        state.chatRequests.add(item);
      }
    },
    deleteChatRequest: (state, action)=> {
      state.chatRequests.delete(action.payload.chatId);
    },
    updateChat: (state, action)=> {
      const chatHistory = state.chats.get(action.payload.chatId);
      if (chatHistory) {
        state.chats = state.chats.set(action.payload.chatId, {...chatHistory, ...action.payload.data});
      }
    },
    updateWithCompId: (state, action)=> {
      const {companionId, data} = action.payload;
      const chats = [...state.chats.values()];
      const updatedItem = chats.find((item)=> item.companion._id === companionId);
      state.chats = state.chats.set(updatedItem._id, {...updatedItem, ...data});
    },
    messagesRead: (state, action)=> {
      const chatHistory = state.chats.get(action.payload);
      
      if (chatHistory && Object.keys(chatHistory?.visitorUnread).length) {
        state.chats = state.chats.set(action.payload, {...chatHistory, visitorUnread: {}});
      }
    },
    removeChat: (state, action)=> {
      const agentChats = state.chats;
      agentChats.delete(action.payload.chatId);
      state.chats = agentChats;
    },
    addWatchingChat: (state, action)=> {
      state.watchingChats = state.watchingChats.set(
        action.payload.chat._id,
        {...action.payload.chat, messages: action.payload.messages[0] || [], unreadCount: action.payload.unreadCount || 0, chatOpen: false});
    },
    removeWatchingChat: (state, action)=> {
      state.watchingChats.delete(action.payload.chatId);
    },
    updateWatchingChat: (state, action)=> {
      const {chatId, user} = action.payload;
      const watchingChat = state.watchingChats.get(chatId);
      state.watchingChats = state.watchingChats.set(chatId, {...watchingChat, user});
    }
  }
});

export const {
  endChatState, 
  deleteChat,
  addHistory, 
  setNewMessage, 
  setUndeliveredMessage,
  setNewChat, 
  addReferrer,
  incomingChats,
  deleteChatRequest,
  updateChat,
  updateWithCompId,
  messagesRead,
  removeChat,
  addWatchingChat,
  removeWatchingChat,
  updateWatchingChat,
} = chatSlice.actions;
export const selectChat = (state) => state.chat;
export default chatSlice.reducer;
