import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  addAgentServ,
  getAgentsByPartnerIdServ,
  getAutoAssignServ,
  removeAgentTemleadServ,
  updateAgentServ,
  updateNotificationServ
} from './agentsApi';
import { sortArrObjByItem } from 'utils/functions';
import { ROLES, ROLE_GROUPS_TEAM } from 'configs/constants';
import { setNotification } from 'redux_toolkit/reducers/notification/notificationSlice';
import { updateUserData } from 'redux_toolkit/reducers/user_partner/userPartnerSlice';

const initialState = {
  request: 0,
  loading: false,
  partnerTeam: new Map(),
  partnerTeamleads: [],
  partnerAgents: [],
  activeAgentsChats: {},
}

export const updateAgent = createAsyncThunk(
  'update_agent',
  async (info, {dispatch})=> {
    const res = await updateAgentServ(info.agentId, info.partnerId, info.data);
    if(res.success) {
      dispatch(setNotification({type: res.message, message: 'success'}));
      return {
        info: info.data,
        agentId: info.agentId
      }
    }
    dispatch(setNotification({type: res.message, message: 'error'}));
    throw new Error('Agent update failed', res.message);
  }
);

export const getAutoAssign = createAsyncThunk(
  'auto_assign_agents',
  async (partnerId)=> await getAutoAssignServ(partnerId)
);

export const addAgent = createAsyncThunk(
  'add_agent',
  async ({partnerId, body}, {dispatch})=> {
    const res = await addAgentServ(partnerId, body);
    if (res.success) {
      dispatch(setNotification({type: res.message, message: 'success', show: true}));      
      return {
        ...res.data,
        email: body.email
      };
    }
    dispatch(setNotification({type: res.message, message: 'error'}))
    throw new Error('addAgent function Error', res.message);
  }
);

export const getAgentsByPartnerId = createAsyncThunk(
  'get_agent_by_partnerId',
  async (partnerId)=> await getAgentsByPartnerIdServ(partnerId)
);

export const removeUser = createAsyncThunk(
  'remove_user',
  async ({agentId, partnerId}, {dispatch})=> {
    const res = await removeAgentTemleadServ(agentId, partnerId);
    if(res.success){
      dispatch(setNotification({type: res.message, message: 'success', show: true}));
      return agentId;
    }
    dispatch(setNotification({type: res.message, message: 'error'}))
  }
)

export const updateNotification = createAsyncThunk(
  'update_notifiaction',
  async ({partnerId, agentId, body}, {dispatch})=> {
    const res = await updateNotificationServ(partnerId, agentId, body);
    if(res.success) {
      dispatch(setNotification({type: res.message, message: 'success', show: true}));
      dispatch(updateUserData(body));
      return {agentId, body};
    }
    dispatch(setNotification({type: res.message, message: 'error'}));
  }
);

export const agentsSlice = createSlice({
  name: 'agent',
  initialState,
  reducers: {
    addNewLoggedAgent: (state, action)=> {
      const agent = state.partnerTeam.get(action.payload._id) || {};
      state.partnerTeam = state.partnerTeam.set(action.payload._id, {...action.payload, ...agent});
    },
    updateAgentLocal: (state, action)=> {      
      const agent = state.partnerTeam.get(action.payload._id);
      if(agent){       
        state.partnerTeam = state.partnerTeam.set(
          action.payload._id, {...agent, ...action.payload.data}
        );

        const roleGroup = ROLE_GROUPS_TEAM[agent.role] || '';
        const agentIndex = state[roleGroup].findIndex(agent => agent._id === action.payload._id);
        if (agentIndex !== -1) {
          state[roleGroup][agentIndex] = {
            ...state[roleGroup][agentIndex],
            ...action.payload.data
          }
        }
      }
    },
    updateChatRequest: (state, action)=> {
      state.request = +action.payload;
    }
  },
  extraReducers: (builder)=> {
    builder
      .addCase(getAutoAssign.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAutoAssign.fulfilled, (state, action) => {
        state.loading = false;
        state.request = action.payload.data;
      })
      .addCase(getAutoAssign.rejected, (state, action) => {
        state.loading = false;
        console.error("Error redux async func 'getAutoAssign'", action.error)
      })
      .addCase(addAgent.pending, (state) => {
        state.loading = true;
      })
      .addCase(addAgent.fulfilled, (state, action) => {
        state.loading = false;
        state.partnerTeam = state.partnerTeam.set(action.payload._id, {...action.payload});
        state[ROLE_GROUPS_TEAM[action.payload.role]].push(action.payload);
      })
      .addCase(addAgent.rejected, (state, action) => {
        state.loading = false;
        console.error("Error redux async func 'addAgent'", action.error)
      })
      .addCase(getAgentsByPartnerId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAgentsByPartnerId.fulfilled, (state, action) => {
        state.loading = false;
        const data = action.payload.data;

        for(let item of data) {
          const agent = state.partnerTeam.get(item._id) || {};
          state.partnerTeam = state.partnerTeam.set(item._id, {...item, ...agent});
        }

        state.partnerTeamleads = sortArrObjByItem(data.filter((item)=> item.role === ROLES.TEAMLEADER), 'email');
        state.partnerAgents = sortArrObjByItem(data.filter((item)=> item.role === ROLES.AGENT), 'email');
      })
      .addCase(getAgentsByPartnerId.rejected, (state, action) => {
        state.loading = false;
        console.error("Error redux async func 'getAgentsByPartnerId'", action.error)
      })
      .addCase(removeUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeUser.fulfilled, (state, action) => {
        state.loading = false;
        state.partnerTeam.delete(action.payload);
        state.partnerTeamleads = state.partnerTeamleads.filter(agent => agent._id !== action.payload);
        state.partnerAgents = state.partnerAgents.filter(agent => agent._id !== action.payload);
      })
      .addCase(removeUser.rejected, (state, action) => {
        state.loading = false;
        console.error("Error redux async func 'addAgent'", action.error)
      })
      .addCase(updateAgent.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateAgent.fulfilled, (state, action) => {
        state.loading = false;
        const agent = state.partnerTeam.get(action.payload.agentId) || {};
        state.partnerTeam = state.partnerTeam.set(action.payload.agentId, {...agent, ...action.payload.info});

        const roleGroup = ROLE_GROUPS_TEAM[agent.role];
        const agentIndex = state[roleGroup].findIndex(agent => agent._id === action.payload.agentId);
        if (agentIndex !== -1) {
          state[roleGroup][agentIndex] = {
            ...state[roleGroup][agentIndex],
            ...action.payload.info
          }
        }
      })
      .addCase(updateAgent.rejected, (state, action) => {
        state.loading = false;
        console.error("Error redux async func 'updateAgent'", action.error)
      })
      .addCase(updateNotification.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateNotification.fulfilled, (state, action) => {
        state.loading = false;
        const agent = state.partnerTeam.get(action.payload.agentId) || {};
        state.partnerTeam = state.partnerTeam.set(action.payload.agentId, {...agent, ...action.payload.body});
      })
      .addCase(updateNotification.rejected, (state, action) => {
        state.loading = false;
        console.error("Error redux async func 'updateAgent'", action.error)
      })
  }
});

export const {
  addNewLoggedAgent,
  updateChatRequest,
  updateAgentLocal
} = agentsSlice.actions;
export const selectAgents = (state) => state.agents;
export default agentsSlice.reducer;