import * as S from "../AgentChat.styles";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button } from "@material-ui/core";
import { Send, AttachFile, Close } from '@mui/icons-material';
import { messageSend, readMessage, openChatPage } from "socket/socket";
import { messagesSort } from "../../ChatDataList/ChatDataList.config";
import { ChatDataList } from "components";
import { isPersian } from "utils/functions";


export const CurrentAgentChat = ({ chatData, agent })=> {
  const [selectedFile, setSelectedFile] = useState('');
  const [file, setFile] = useState(null);
  const [textDirection, setTextDirection] = useState('ltr');
  const textAreaRef = useRef();
  const sortedMessages = useMemo(() => messagesSort(chatData.messages || []), [chatData.messages]);


  const handleChangeFile = useCallback((e) => {
    const fileToUpload = e.target.files[0];
    if(fileToUpload.size <= 4000000) {
      setFile(fileToUpload);
      const reader = new FileReader();
      reader.readAsDataURL(fileToUpload);

      reader.onloadend = function(e) {
        setSelectedFile(reader.result);
      }
    }
    e.target.value = null;
  }, []);

  const handleChange = useCallback((e) => {
    const char = e.target.value.trim();

    if (isPersian(char.charAt(0))) {
      setTextDirection('rtl');
    } else {
      setTextDirection('ltr');
    }
  }, []);

  useEffect(()=> {    
    openChatPage(chatData?._id);
  }, [chatData?._id]);

  useEffect(()=>{
    readMessage(chatData?._id);
  },[chatData]);

  const onEnterPress = (e) => {
    if (e.key === 'Enter' && e.shiftKey === false) {
      e.preventDefault();
      send();
    }
  };

  const send = () => {
    const message = textAreaRef.current.value;
    if ((message.trim() || file) && chatData?._id) {
      messageSend(chatData._id, message.trim(), file);
      setFile('');
      setSelectedFile('');
      textAreaRef.current.value = '';
    }
  };

  return(
    <S.ChatContent>
      <ChatDataList
      messagesChat={sortedMessages}
      agentName={agent.name}
      chatId={chatData._id}
      chatCompanionId={chatData.companion._id}
      chatLogUsers={chatData.logUsers}
      />
      {!chatData?.chatEnd && <S.EditorContainer>
          <S.TextArea
            ref={textAreaRef}
            minRows={6}
            onKeyDown={onEnterPress}
            style={{ direction: textDirection }}
            onChange={handleChange}
          />
          <S.EditorButtonsContainer>
            <Button aria-label="Attach file">
              <S.InputLabel htmlFor="file-input">
                <AttachFile />
              </S.InputLabel>
              <S.FileInput
                accept="video/*, image/*"
                id="file-input"
                max="500"
                type="file"
                onChange={handleChangeFile}
              />
            </Button>
            <Button onClick={send}>
              <Send />
            </Button>
          </S.EditorButtonsContainer>
        </S.EditorContainer>}
        {selectedFile &&
          <S.FileContent>
            <Close onClick={() => {
              setSelectedFile('');
              setFile('');
              document.getElementById("file-input").value = null;
            }} />
            {file.type?.split('/')[0] === 'image' ?
              <S.SelectedImage src={selectedFile} /> : <S.SelectedVideo src={selectedFile} />
            }
          </S.FileContent>
        }
    </S.ChatContent>
  )
}