import cn from "classnames";
import * as S from "./ChatTabs.styles";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { STATUS_CHAT_VALUES } from "configs/constants";
import { endThisChat, openChatPage, getChat } from "socket/socket";
import { showModal, hideModal } from "redux_toolkit/reducers/modals/modalsSlice";


export const ChatTabs = () => {
  const dispatch = useDispatch();
  const { chats, chatRequests } = useSelector(({ chat }) => chat);
  const { partnerTeam } = useSelector(({ agents }) => agents);

  const [openTabs, setOpenTabs] = useState("");
  const [previousTab, setPreviousTab] = useState("");
  const isInternalChat = useCallback((chat) => {
    return chat.status === STATUS_CHAT_VALUES.INTERNAL ? true : false
  }, []);

  const allChats = useMemo(() => [...chats.values()], [chats]);

  const findAgent = useCallback((id) =>  partnerTeam.get(id), [partnerTeam]);

  const openChat = useCallback((chat) => {
    openChatPage(chat._id);
    setOpenTabs(chat._id);

    if(chat.status !== STATUS_CHAT_VALUES.SERVED && chat.status !== STATUS_CHAT_VALUES.TRANSFER) getChat(chat._id);

    dispatch(showModal({
      type: isInternalChat(chat) ? 'internal' : 'clientChat',
      chatId: chat._id,
      agent: { ...findAgent(chat.companion._id), chatId: chat._id },
      showOne: true
    }));
  },[findAgent, isInternalChat, dispatch]);

  const changeTabs = useCallback(
    async (e) => {
      e.stopPropagation();
      if (e.ctrlKey && e.keyCode === 192) {
        if(!chatRequests.size) {
          openChat(allChats[allChats.length - 1]);
        }
      } else if (e.altKey && (e.keyCode === 219 || e.keyCode === 221)) {
        const currentIndex = allChats.findIndex(
          (i) => i._id === openTabs && i.chatOpen
        );

        const direction = e.keyCode === 221 ? 1 : -1;
        const directionIndex = currentIndex + direction;

        let i = 0;
        if(currentIndex >= 0 && (directionIndex < allChats.length))
          i = directionIndex;

        if((currentIndex === -1 && e.keyCode === 221) || (e.keyCode === 219 && currentIndex === 0))
          i = allChats.length - 1;

        dispatch(hideModal(allChats[currentIndex]));
        openChat(allChats[i]);
      } else if (
        e.keyCode >= 49 &&
        e.keyCode <= 57 &&
        e.ctrlKey &&
        Number(e.key) <= allChats.length
      ) {
        e.preventDefault()
        const tab = allChats[e.keyCode - 49];
        setPreviousTab(tab);
        dispatch(hideModal(previousTab));
        openChat(tab);
      }
    },
    [allChats, chatRequests.size, openChat, openTabs, previousTab, dispatch]
  );

  useEffect(() => {
    window.removeEventListener("keydown", changeTabs);
    window.addEventListener("keydown", changeTabs);
    return () => {
      window.removeEventListener("keydown", changeTabs);
    };
  }, [changeTabs]);

  const getUnseenMessagesCount = useCallback((chat) => chat.unreadCount, []);

  return (
    <S.ChatList>
      {allChats?.map((chat, index) => (
        <S.ChatCard
          key={index}
          onClick={() => openChat(chat)}
          className={cn({
            readedAllMessages: getUnseenMessagesCount(chat) === 0,
          })}
        >
          <S.ClientName>{chat.companion?.name}</S.ClientName>
          {getUnseenMessagesCount(chat) !== 0 && (
            <S.UnreadMessagesCountContainer>
              <span>
                {getUnseenMessagesCount(chat) > 9
                  ? "9+"
                  : getUnseenMessagesCount(chat)}
              </span>
            </S.UnreadMessagesCountContainer>
          )}
          <S.CloseButton
            onClick={(e) => {
              e.stopPropagation();
              dispatch(showModal({
                type: 'confirmation',
                title: `Are you sure you want to end chat with "${chat.companion?.name}" ?`,
                hideModal: hideModal,
                confirmText: "End Chat",
                onConfirm: () => {
                  endThisChat({chatId: chat._id, companionUserId: chat.companion._id, agent: chat.user.name, createdAt: chat.messages[4]});
                },
              }));
            }}
          >
            <CloseIcon />
          </S.CloseButton>
        </S.ChatCard>
      ))}
    </S.ChatList>
  );
};
