import { MESSAGES_ITEM_COUNT, MESSAGE_STATUS } from "configs/constants";

export const messagesSort = (messages, messagesUnread=[], undeliveredMessages)=> {
  let messagesData = [];
  // if(typeof messages[0] === 'object'){    
  //   for(let i = messages.length-1; i >= 0; i--){
  //     const {id, userId, message, file, date} = messages[i];
  //     messagesData.push([id, userId, message, file, date]);
  //   }
  //   return messagesData;
  // }

  for (let i = 0; i < messages.length; i += MESSAGES_ITEM_COUNT) {
    messagesData.push([
      ...messages.slice(i, i + MESSAGES_ITEM_COUNT),
      messagesUnread[messages[i]]? MESSAGE_STATUS.DELIVERID:MESSAGE_STATUS.READ
    ]);
  }
  if(typeof undeliveredMessages === 'object') messagesData.push(...Object.values(undeliveredMessages));

  return messagesData;
}