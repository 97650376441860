import * as S from "./PartnersContainer.style";
import { memo } from "react";
import { PartnerItem } from "components";


export const PartnersContainer = memo(({currentUser, partners})=> {
  return(
    <>
      <S.Email>{currentUser?.email}</S.Email>
      <S.Title>Dashboards</S.Title>
       {!partners.length ? (
        <S.NoPartner>No Partners</S.NoPartner>
       ) : (
        <S.PartnersList>
          {partners?.map((partner) => {
            return (
              <PartnerItem
                key={partner._id}
                currentUser={currentUser}
                partner={{
                  ...partner,
                  status: partner.status,
                  role: partner.role,
                }}
              />
            );
          })}
        </S.PartnersList>
      )}
    </>
  )
}, (prevProps, nextProps)=> {
  return (
    prevProps.currentUser === nextProps.currentUser &&
    prevProps.partners === nextProps.currentUser
  )
});