export const debounce = (func, wait)=> {
  let timeout;
  return function executedFunction(...args) {
    clearTimeout(timeout);

    timeout = setTimeout(()=> {
      clearTimeout(timeout);
      func(...args);
    }, wait);
  };
}

export const compact = (array)=> {
  return array.filter(Boolean);
}

export const isEmpty = (value)=> {
  if (value === null || value === undefined) return true;

  if (Array.isArray(value) || typeof value === 'string' || value instanceof Map || value instanceof Set) return value.length === 0;

  if (typeof value === 'object')  return Object.keys(value).length === 0;

  return !value;
}

export const upperFirst = (str)=> {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const dropRight = (array, n = 1)=> {
    if (!Array.isArray(array) || array.length === 0 || n <= 0) return [];
    return array.slice(0, array.length - n);
}

export const omit = (obj, keys) => {
  const newObj = {};
  for (let key in obj) {
    if (!keys.includes(key)) newObj[key] = obj[key];
  }
  return newObj;
};

export const uniqBy = (array, iteratee)=> {
  const seen = new Set();
  return array.filter(item => {
    const key = typeof iteratee === 'function' ? iteratee(item) : item[iteratee];
    if (seen.has(key)) {
      return false;
    }
    seen.add(key);
    return true;
  });
}

export const getDefaultId = ()=> ((Math.random()*10)+(Math.random()*100));

export const findStringItem = (arr, text)=> {
  return arr.find(item => text.toLowerCase().includes(item));
};

export const sortArrObjByItem = (arr, item)=> {
  return arr?.sort((a, b) => a[item].localeCompare(b[item]));
}

export const isPersian = (char)=> {
  const persianCharPattern = /[\u0600-\u06FF]/;
  return persianCharPattern.test(char);
}

export const messageWithURL = (str) => {
  const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+(?:\.[^\s]+)+)/g;
  const formatURL = (url) => (url.startsWith("www.") ? `https://${url}` : url);

  if(urlRegex.test(str)){
    const partsMsg = str.split(urlRegex);
    return(
      partsMsg.map((item)=> {
        return(
          urlRegex.test(item) ? (
            <a
              key={getDefaultId()}
              href={formatURL(item)}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "blue", textDecoration: "underline" }}
            >
              {item}
            </a>
          ) : <span className='message-content' key={getDefaultId()}>{item}</span>
        )
      })
    )
  };
  return str;
};

export const isEnableNotfBrowser = ()=> {
  if (!("Notification" in window)) {
    alert("This browser does not support notifications.");
    return false;
  }
  if (Notification.permission !== "granted") {
    const message = `Notifications are blocked. Please enable them in your browser settings.

      To enable notifications, follow these steps:

      1. Google Chrome, Opera:
        - Click the lock icon in the address bar.
        - Select "Site settings".
        - In the "Notifications" section, choose "Allow".

      2. Firefox:
        - Click the lock icon in the address bar.
        - Click "More Information".
        - Find "Permissions" and set "Notifications" to "Allow".

      3. Microsoft Edge:
        - Click the lock icon in the address bar.
        - Select "Site permissions".
        - In the "Notifications" section, choose "Allow".
      `;
    alert(message);
    return false;
  }

  return true;
}


// It can be used if decided delete data form redux and use it with func.
// export const sortTeamRoles = (data)=> {
//   let teamleads = [];
//   let agents = [];

//   sortArrObjByItem(data, 'email');

//   for(let item of data){
//     if(item.role === ROLES.TEAMLEADER){
//       teamleads.push(item);
//     }
//     if(item.role === ROLES.AGENT){
//       agents.push(item);
//     }
//   }
//   return { teamleads, agents };
// }