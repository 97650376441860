import moment from "moment";

export const tableHead = [
  'Visitor id',
  'Agent',
  'Date',
  'Rating'
];

export const ratings = [
  {
    id: 6,
    label: 'Rating',
    value: -1
  },
  {
    id: 1,
    label: 1,
    value: 1
  },
  {
    id: 2,
    label: 2,
    value: 2
  },
  {
    id: 3,
    label: 3,
    value: 3
  },
  {
    id: 4,
    label: 4,
    value: 4
  },
  {
    id: 5,
    label: 5,
    value: 5
  },
];

export const types = [
  {
    id: 0,
    label: 'All',
    value: 0
  },
  {
    id: 1,
    label: 'Served',
    value: 5
  },
  {
    id: 2,
    label: 'Missed',
    value: 6
  }
];

export const formatTableData = (historyList) => {
  const formattedList = [];
  for(let i = historyList.length-1; i >= 0; i--){
    formattedList.push([
      historyList[i].visitorPartner[0]?.user?.name,
      historyList[i].agentPartner[0]?.user?.name,
      historyList[i].createdAt && moment(historyList[i].createdAt).format('DD/MM/YYYY HH:mm:ss'),
      historyList[i].rating !== 'undefined' ? historyList[i].rating : '-',
      historyList[i]
    ])
  }

  return formattedList
}

export const getMessageUserName = (id, partnerTeam, visitor) => {
  let resultName = {name: '', agentMessage: false};

  const agent = partnerTeam.get(id);  
  if(agent) resultName = {name: agent.name, agentMessage: true};

  if(!resultName.agentMessage && id === visitor._id) resultName.name = visitor.name;

  return resultName;
}